import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

// 销售组织列表
export function getDealerList(data) {
  return dmshttp({
    url: "getDealerList",
    data
  });
}

// 仓库列表
export function getWarehouseListApi(data) {
  return dmshttp({
    url: "getWarehouseList",
    data
  });
}
// 获取销售组织信息
export function getGicStockTransBaseInfo(data) {
  return dmshttp({
    url: "getGicStockTransBaseInfo",
    data
  });
}
// 获取配送方式信息
export function getDeliveryModeApi(data) {
  return dmshttp({
    url: "getDeliveryMode",
    data
  });
}

// 保存库存调整
export function gicStockTransSave(data) {
  return dmshttp({
    url: "gicStockTransSave",
    data
  });
}

export function getStatsInfo(data) {
  return dmshttp({
    url: "getDealerStockTransStatsInfo",
    data
  });
}

export function getTOF(data) {
  return http({
    // url: '/app/storeCheck/findResultList.do',
    url: 'cts/ctsApi.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}
export function getDeliveryAmountFind(data) {
  return http({
    // url: '/app/storeCheck/findResultList.do',
    url: 'cts/ctsApi.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}
