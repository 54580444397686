import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

// 销售订单审核详情查询
export function getOrderOutList(data) {
    return dmshttp({
        url: "findSalesOrderDetail",
        data
    });
}
export function findSalesOrderOutDetail(data) {
    return dmshttp({
        url: "findSalesOrderOutDetail",
        data
    });
}


// 获取出库表格信息
export function getTableList(data) {
    return dmshttp({
        url: 'findMaterialInventoryList',
        data
    })
}

// 点击出库
export function saleOrderOut(data) {
    return dmshttp({
        url: 'salesOrderOut',
        data
    })
}

// 驳回
export function orderReject(data){
    return dmshttp({
        url:'rejectPurchaseOrder',
        data
    })
}

// 订单审核通过
export function orderPass(data){
    return dmshttp({
        url:'examPurchaseOrder',
        data
    })
}

// 判断是否启用补差类型
export function difference(data){
    return dmshttp({
        url:'isEnableOrNot',
        data
    })
}
export function changeTransFlag(data) {
    return http({
        url: '/marketActivity/changeTransFlag.nd',
        method: 'post',
        data
    })
  }

export function queryShareFlag(data) {
    return http({
        url: '/customer/queryShareFlag.nd',
        method: 'post',
        data
    })
}

export function sysconfig(data) {
    return http({
        url: '/comm/sysconfig.nd',
        method: 'post',
        data
    })
}
