import bg from '@/assets/purchaseOrder/bg.png'
import Process from './components/Process.vue'
import { getDeliveryAmountFind } from "@/views/stockCenter/adjustmentApply/allot/api";
import moment from "moment";
import {
    // 销售出库订单列表查询
    getOrderOutList,
    // 获取出库表格信息
    getTableList,
    // 审核通过
    orderPass,
    //    驳回
    orderReject,
    difference,
    changeTransFlag,
    queryShareFlag,
    sysconfig, findSalesOrderOutDetail
} from "./api";
import { saleOrderOut } from "@/views/order/channelWarehouseOut/api";
import { selectServiceFree } from "@/views/order/details/api";


moment.locale("zh-cn");
export default {
    name: "channelWarehouse",
    components: {
        Process
    },
    data() {
        return {
            breadcrumbData: [{
                path: "/index",
                name: "index",
                title: "首页"
            },
            {
                path: "/channelOrder/orderList",
                name: "orderList",
                title: "订单列表"
            },
            {
                path: "/order/detail",
                name: "detail",
                title: "订单详情"
            }
            ],
            bg: bg,
            //表格搜索
            tableSearch: '',
            columns: [{
                title: '仓库',
                dataIndex: 'warehouseName',
                width: 230,
                ellipsis: true
            },
            {
                title: '产品型号',
                dataIndex: 'model',
                width: 180,
                ellipsis: true
            },
            {
                title: '入库时间',
                dataIndex: 'inInvDate',
                width: 140,
            },
            {
                title: '定价组',
                dataIndex: 'priceGroupName',
                width: 140,
            },
            {
                title: '质量等级',
                dataIndex: 'quvlitylv',
                width: 120,
            },
            {
                title: '补差类型',
                dataIndex: 'invStatusTypeName',
                width: 120
            },
            {
                title: '物料号',
                dataIndex: 'materialCode',
                width: 150,
            },
            {
                title: '库存价格（元）',
                dataIndex: 'price',
                width: 150,
            },
            {
                title: '实际库存',
                dataIndex: 'totalBig',
                width: 100,
            },
            {
                title: '预留数量',
                dataIndex: 'bigQtyLock',
                width: 100,
            },

            {
                title: '可用数量',
                dataIndex: 'bigQty',
                width: 130,
            },
            {

                title: '本次出库数量',
                dataIndex: 'numberThis',
                scopedSlots: {
                    customRender: "count"
                },
                fixed: 'right',
                width: 180,
            },
            ],
            // 表格数据
            tableData: [],
            // 商品list
            goodsList: [],
            // 仅查看已选
            onlyShowChecked: false,
            sendObject: [],
            // 基本信息数据
            orderDetail: {
                orderHeader: {
                    documentNum: '订单编号',
                    documentType: '订单类型',
                    statusName: '订单状态',
                    // canCancel: '123',
                    orgName: '供应商',
                    customerName: '客户',
                    billToName: '结算单位',
                    documentDate: '下单时间：',
                    purchaseNum: '关联采购单号：',
                    addressDesc: '收货地址明细',
                    deliveryTypeTitleValue: '配送方式：',
                    contact: '联系人：',
                    contactInfo: '联系电话：',
                    edt: '期望送货日期：',
                    isAllowAdvancDeliver: '允许提前交货：',
                    payType: '支付方式：',
                    dataSource: '来源',
                    customerCode: '下单账号',
                    message: '订单来源：'
                }
            },
            // 接口数据备份
            NeworderDetail: {
                NeworderHeader: {}
            },
            selectedRows: [],
            chukuTotal: 0,
            chukuVolume: 0,
            serviceFee: 0,// 服务费
            paymentGoods: 0,// 货款合计

            daichuku: 0,
            // 全选
            selectedRowKeys: [],
            // 搜索用
            orgCode: '',
            searchLength: 0,
            totalIndex: 0,
            pageLoadFlag: true,
            isLoading: false,
            isLoadingReturn: false,
            // buchais: false,
            visibleshop: false,
            shareType: '',
            shareTypezong:'',// 总开关
            cherkStatus:false,
            visibleAAA:false,
            showInfo:'',
            openCloseBtn:false,
            showTips:false,
            msgStatus:'',
            checkNumFlag: false,
            filterArr:[],
            smallLoanSwitch:false,// 小贷总开关
            serviceFeeTotal:0,
            type:'',
            notFullyReviewed:[],
            submitArraySH:[],
            tipMessageVisible:false,
            tipMessage:'',
            brightNum:0,// 璀璨数量
            brightPrice:0,//璀璨价格
            olnyNum:0,// 专供数量
            olnyPrice:0,//专供价格
        };
    },
    computed: {
        orderDetail(){
            this.orderDetail.orderHeader.salesOrderItem.tableList

        }

    },
    watch:{
        filterArr: {
            handler: function handler(value) {
                if(value.length > 0   && this.showTips &&  this.smallLoanSwitch){
                    this.openCloseBtn = true
                    this.cherkStatus = true
                } else {
                    this.openCloseBtn = false
                    this.cherkStatus = false
                }
            },
            deep: true
        }
    },
    mounted() {
        this.type = this.$route.query.type
        if(this.$route.query.type =='CK'){
            this.getOrderListCK();
        } else {
            // 销售出库列表查询
            this.getOrderList();
        }

        this.getisKQ()
        this.getLoanSwitch()

    },

    methods: {
        //出库页面操作
        // 判断商品是否包含免运费标识
        panduanfreeTip() {
            let mian = []
            this.orderDetail.orderHeader.salesOrderItem.forEach(item=>{
                if(item.productLabel && item.productLabel.indexOf('15691143850')!==-1) {
                    mian.push(item)
                }
            })
            if(mian.length>0 &&  this.orderDetail.orderHeader.deliveryTypeCode !== '04') {
                this.visibleshop = true
            } else {
                this.orderSubmitCK()
            }
        },
        getOrderListCK() {
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                salesOrderId: this.$route.query.id
            }
            findSalesOrderOutDetail(data).then(res => {
                var realData = JSON.parse(res.data);
                // this.bucha(realData.data.orgId)
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                    this.$router.go(-1);
                }
                var dataList = realData.data.salesOrderItem;

                this.orgCode = realData.data.orgCode;

                var newArray = [];
                for (var i = 0; i < dataList.length; i++) {
                    let datas = {};
                    this.daichuku = this.daichuku + Number(dataList[i].backnowledgedQty);
                    //  分销商采购单查询条件
                    if (realData.data.documentType == 'normal' && realData.data.purchaseNum != '') {
                        datas = {
                            cisCode: this.$store.state.user.userInfo.customer.cisCode,
                            terms: {
                                isFuzzy:false,
                                code:dataList[i].productCode,
                                zzprdmodel:dataList[i].zzprdmodel,
                                orgCode: realData.data.orgId,
                                warehouseId: dataList[i].warehouseId,
                                invStatusId: dataList[i].invStatusId,
                                model: dataList[i].model,
                                invType: 110,
                                materialCode:dataList[i].materialCode
                            },
                            page: {
                                pageNo: 1,
                                pageSize: 100
                            }
                        }
                    } else {
                        datas = {
                            cisCode: this.$store.state.user.userInfo.customer.cisCode,
                            terms: {
                                isFuzzy:false,
                                zzprdmodel:dataList[i].zzprdmodel,
                                code:dataList[i].productCode,
                                orgCode: realData.data.orgId,
                                model: dataList[i].model,
                                invType: 110,
                                materialCode:dataList[i].materialCode
                            },
                            page: {
                                pageNo: 1,
                                pageSize: 100
                            }
                        }
                    }
                    this.pageLoadFlag = false;
                    // this.getTable(data, i)
                    // 查询商品库存
                    let productCode = []
                    productCode.push(dataList[i].productCode)
                    let dataA = {
                        documentNum:realData.data.documentNum,
                        productCodes:productCode
                    }
                    newArray.push(getTableList(datas))
                };
                Promise.all(newArray).then(value => {
                    for (let i = 0; i < value.length; i++) {
                        // var newArrays = [];
                        // 如果是gic预留  则可出库数量 == 预留数量
                        // 非 gic预留仓库
                        // 本次出库数量需要 小于 可出库数量 - 已出库数量
                        var kechuku = 0;
                        if (value[i].data) {
                            var realValue = JSON.parse(value[i].data);
                            realData.data.salesOrderItem[i].bencichuku = 0;
                            realData.data.salesOrderItem[i].checkAll = false;
                            realData.data.salesOrderItem[i].selectedRowKeys = [];
                            for (let y = 0; y < realValue.data.length; y++) {
                                realValue.data[y].index = i;
                                realValue.data[y].price = realData.data.salesOrderItem[i].bprice;
                                realValue.data[y].numberThis = 0;
                                realValue.data[y].key = y;
                                realValue.data[y].selectedTableRow = false;
                                // 可用库存
                                let jobQty = realValue.data[y].bigQty
                                if (realValue.data[y].isShare == '1') {
                                    realValue.data[y].bigQty = realValue.data[y].bigQtyLock;
                                } else {
                                    realValue.data[y].bigQty = Number(realValue.data[y].bigQty) - Number(dataList[i].shippedBqty);
                                }
                                // 如果来源是job 赋值可用库存
                                if(realData.data && realData.data.dataSource == 'job') {
                                    realValue.data[y].bigQty = Number(jobQty);
                                }
                                kechuku = Number(kechuku) + realValue.data[y].bigQty;
                            }
                            realData.data.salesOrderItem[i].searchLength = realValue.data.length;
                            realData.data.salesOrderItem[i].tableList1 = realValue.data;
                            realData.data.salesOrderItem[i].tableList = realValue.data;
                            realData.data.salesOrderItem[i].kechuku = Number(kechuku);

                            // 判断详情中的仓库和所获得的仓库id是否一致 一致的话就指定当前仓库 如果不一致的话就展示全部让用户自己勾选
                            realData.data.salesOrderItem[i].tableList = []
                            realData.data.salesOrderItem[i].tableList1.forEach((item,index)=>{
                                dataList.forEach((saleItem,saleIndex) =>{
                                    // 判断循环的saleIndex 和 realData.data.salesOrderItem的index 是否一致 一致才会进行下一步判断
                                    if(saleIndex === i) {
                                        if( item.gicWarehouse === saleItem.warehouseId ) {
                                            // 判断产品productCode 是否一致  一致才push数组中
                                            if(item.productCode === saleItem.productCode) {
                                                realData.data.salesOrderItem[i].tableList.push(item)
                                            }
                                            let kechuku = 0
                                            let bencichuku = 0
                                            // 本次出库需要赋值为详情中商品的可出库库存
                                            realData.data.salesOrderItem[i].bencichuku = saleItem.backnowledgedQty
                                            for (let y = 0; y < realValue.data.length; y++) {
                                                // 数字选择器赋值为详情中商品的可出库库
                                                realValue.data[y].numberThis = saleItem.backnowledgedQty
                                                // realValue.data[y].numberThis = 0
                                            }
                                            realData.data.salesOrderItem[i].searchLength =  realData.data.salesOrderItem[i].tableList.length;
                                            // 可用库存重新赋值
                                            realData.data.salesOrderItem[i].tableList .forEach(pitem=>{
                                                kechuku = Number(kechuku) + Number(pitem.bigQty);
                                                bencichuku = bencichuku+= pitem.numberThis
                                            })
                                            realData.data.salesOrderItem[i].kechuku = Number(kechuku);
                                            realData.data.salesOrderItem[i].bencichuku = 0
                                        } else {
                                            // 如果详情仓库id和仓库列表不符合 保持原来的赋值方式
                                            realData.data.salesOrderItem[i].tableList = realValue.data;
                                            realData.data.salesOrderItem[i].searchLength =  realData.data.salesOrderItem[i].tableList.length;
                                            let chuku = 0
                                            // 仓库id如果对应不上可用库存重新赋值
                                            realData.data.salesOrderItem[i].tableList .forEach(pitem=>{
                                                chuku = chuku + pitem.bigQty;
                                            })
                                            realData.data.salesOrderItem[i].kechuku = Number(chuku);

                                        }
                                    }

                                })
                            })
                        }
                    }
                    this.orderDetail.orderHeader = realData.data;
                     
                    this.NeworderDetail.NeworderHeader = JSON.parse(JSON.stringify(realData.data));
                    this.$forceUpdate();


                })
            }).finally(() => {
                this.pageLoadFlag = false;
            })
        },
        //提交
        async orderSubmitCK() {
            // 如果dataSource == job  不进行出库验证
            if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.dataSource == 'job') {
                let realData = this.sendObject;

                if (realData.length == 0) {
                    this.$message.warning('请先选择仓库');
                    return;
                } else {
                    let TJmoney = 0
                    let  submitArray = [];
                    let  realValue = this.orderDetail.orderHeader.salesOrderItem
                    let isFinished = true;
                    for (let i = 0; i < realData.length; i++) {
                        let submitData = {},
                          outThis = 0;
                        if (realData[i]) {
                            for (let z = 0; z < realData[i].length; z++) {

                                if (realData[i][z].numberThis == 0) {
                                    this.$message.warning('第' + Number(i + 1) + '个商品仓库数据不能为0')
                                    return;
                                }
                                if (realValue[i].bencichuku < realValue[i].backnowledgedQty - realValue[i].shippedBqty) {
                                    isFinished = false;
                                }
                                outThis = outThis + realData[i][z].numberThis;
                                if(realData[i][z].gicWarehouseType == '20') {
                                    TJmoney += (realData[i][z].numberThis * realData[i][z].price)
                                }
                                submitData = {
                                    materialGroupCode: realData[i][z].materialGroupCode,
                                    invStatusId: realData[i][z].invStatusId,
                                    backnowledgedQty: realValue[i].backnowledgedQty,
                                    iceBoxNum: '', //realValue[i].iceBoxNum,
                                    invStatusType: realData[i][z].invStatusType,
                                    productName: realData[i][z].productName,
                                    itemId: realValue[i].itemId,
                                    colour: realData[i][z].colour,
                                    productCode: realValue[i].productCode,
                                    warehouseId: realData[i][z].gicWarehouse,
                                    model: realData[i][z].model,
                                    shippedBqty: realData[i][z].numberThis,
                                    bprice: realValue[i].bprice,
                                    invStatus: realData[i][z].invStatusId,
                                    invBatchId: ''
                                }
                                submitArray.push(submitData);
                            }
                        }
                    }
                    if (submitArray.length == 0) {
                        this.$message.warning('请先选择仓库');
                        return;
                    }
                    this.isLoading = true;
                    let datas = {
                        salesOrderId: this.$route.query.id,
                        salesOrderItem: submitArray,
                        isFinished: isFinished,
                        dataSource: 'xtw_pc'
                    }
                    // 订单出库
                    let data = {
                        cisCode: this.$store.state.user.userInfo.customer.cisCode,
                        userAccount: this.$store.state.user.userInfo.account.account,
                        data: datas
                    }
                    saleOrderOut(data).then(res => {
                        this.isLoading = false;
                        let realData = JSON.parse(res.data);
                        if (realData.code == '1') {
                            this.$message.warning(realData.msg);
                        } else if (realData.code == '0') {
                            this.$message.success('出库成功')
                            localStorage.setItem('XTW', new Date());
                            localStorage.setItem("saleOrderListTime", new Date().getTime());
                            window.close()
                            this.$router.push('/channelOrder/orderList');
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    })
                }
            } else {
                if (this.daichuku < this.chukuTotal) {
                    this.$message.warning('出库数量不得大于待出库数量');
                    return;
                }
                let realData = this.sendObject;
                if (realData.length == 0) {
                    this.$message.warning('请先选择仓库');
                    return;
                } else {

                    let  submitArray = [];
                    let  realValue = this.orderDetail.orderHeader.salesOrderItem
                    let isFinished = true;
                    let TJmoney = 0
                    for (let i = 0; i < realData.length; i++) {
                        let submitData = {},
                          outThis = 0;
                        if (realData[i]) {
                            for (let z = 0; z < realData[i].length; z++) {
                                if (realData[i][z].numberThis == 0) {
                                    this.$message.warning('第' + Number(i + 1) + '个商品仓库数据不能为0')
                                    return;
                                }
                                if (realValue[i].bencichuku > realValue[i].backnowledgedQty - realValue[i].shippedBqty) {
                                    this.$message.warning('出库数量不能大于已审核的剩余出库数量')
                                    return;
                                }
                                if (realValue[i].bencichuku < realValue[i].backnowledgedQty - realValue[i].shippedBqty) {
                                    isFinished = false;
                                }
                                outThis = outThis + realData[i][z].numberThis;
                                if(realData[i][z].gicWarehouseType == '20') {
                                    TJmoney += (realData[i][z].numberThis * realData[i][z].price)
                                }

                                submitData = {
                                    materialGroupCode: realData[i][z].materialGroupCode,
                                    invStatusId: realData[i][z].invStatusId,
                                    backnowledgedQty: realValue[i].backnowledgedQty,
                                    iceBoxNum: '', //realValue[i].iceBoxNum,
                                    invStatusType: realData[i][z].invStatusType,
                                    productName: realData[i][z].productName,
                                    itemId: realValue[i].itemId,
                                    colour: realData[i][z].colour,
                                    productCode: realValue[i].productCode,
                                    warehouseId:realData[i][z].gicWarehouse,
                                    model: realData[i][z].model,
                                    shippedBqty: realData[i][z].numberThis,
                                    bprice: realValue[i].bprice,
                                    invStatus: realData[i][z].invStatusId,
                                    invBatchId: ''
                                }
                                submitArray.push(submitData);
                            }
                            if (outThis > realValue[i].backnowledgedQty) {
                                this.$message.warning('第' + Number(i + 1) + '个商品出库数量大于商品该出库数量')
                                return;
                            }
                        }
                    }
                    if (submitArray.length == 0) {
                        this.$message.warning('请先选择仓库');
                        return;
                    }
                    this.isLoading = true;
                    let datas = {
                        salesOrderId: this.$route.query.id,
                        salesOrderItem: submitArray,
                        isFinished: isFinished,
                        dataSource: 'xtw_pc'
                    }
                    // 订单出库
                    let data = {
                        cisCode: this.$store.state.user.userInfo.customer.cisCode,
                        userAccount: this.$store.state.user.userInfo.account.account,
                        data: datas
                    }

                    saleOrderOut(data).then(res => {
                        this.isLoading = false;
                        let realData = JSON.parse(res.data);
                        if (realData.code == '1') {
                            this.$message.warning(realData.msg);
                        } else if (realData.code == '0') {

                            this.$message.success('出库成功')
                            localStorage.setItem('XTW', new Date());
                            localStorage.setItem("saleOrderListTime", new Date().getTime());
                            window.close()
                            this.$router.push('/channelOrder/orderList');
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    })
                }
            }
        },


        // 获取小贷总开关
        async getLoanSwitch() {
            const res =  await this.getType({ key:'QD_OPEN_SMALL_LOANS'})
            this.smallLoanSwitch = res.data.data == 'Y' ? true : false
        },
        // 更改状态
        changeStatus(){
            this.cherkStatus = !this.cherkStatus
            this.$forceUpdate()
        },
        // 判断当前账户是否签约贷款合同
        getisKQ(){
            let data = {
                serviceCode:'queryDeliveryAmountFind',
                mdmCode:this.$store.state.user.userInfo.customer.customerCode
            }
            getDeliveryAmountFind(data).then(res=>{
                if(res.data.success == 'true'){
                    this.showTips = res.data.msg=='true'?true:false
                    this.cherkStatus = true
                    this.$forceUpdate()
                }else {
                    this.openCloseBtn = false
                }
            })
        },
        getType(data) {
            return sysconfig(data)
        },

        // 销售审核列表查询
        // userAccount: this.$store.state.user.userInfo.account.account,
       async getOrderList() {
            const res =  await this.getType({ key:'QD_ONLY_SHARE_STORE'})
            this.shareTypezong = res.data.data
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                salesOrderId: this.$route.query.id
            }
            getOrderOutList(data).then(async (res) => {
                var realData = JSON.parse(res.data);
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                    return
                }
                // this.bucha(realData.data.orgCode)
                var dataList = realData.data.salesOrderItem;
                this.orgCode = realData.data.orgCode;
                var newArray = [];
                for (var i = 0; i < dataList.length; i++) {
                    this.daichuku = this.daichuku + Number(dataList[i].backnowledgedQty);
                    //  查询光伟的接口（入参：组织、物料组，返回值：Y、N）校验物料组是否开启共享 开启共享传参只查询统仓
                    var gicWarehouseType = ''
                    if(this.shareTypezong == 'Y') {
                        let shareData = {
                            orgId:realData.data.orgCode ,
                            matklId:dataList[i].materialGroupCode
                        }
                        await queryShareFlag(shareData).then(res => {
                            this.shareType = res.data.data
                            if(res.data.data == 'Y') {
                                gicWarehouseType = '20'
                            } else {
                                gicWarehouseType = ''
                            }
                        })
                    }

                    let datas = {
                        cisCode: this.$store.state.user.userInfo.customer.cisCode,
                        terms: {
                            isFuzzy:false,
                            zzprdmodel:dataList[i].zzprdmodel,
                            code:dataList[i].productCode,
                            orgCode: realData.data.orgId,
                            model: dataList[i].model,
                            // warehouseId: dataList[i].gicOutWarehouse,
                            // 去掉查询当前行的质量等级的数据
                            // invStatusId: dataList[i].invStatusId,
                            invType: 110,
                            // 库存类别只查正品
                            gicInvStatus: "110",
                            // 只查共享仓库存
                            gicWarehouseType:gicWarehouseType,

                        },
                        page: {
                            pageNo: 1,
                            pageSize: 100
                        }
                    }

                    // this.getTable(data, i)
                    this.pageLoadFlag = false;
                    newArray.push(getTableList(datas))
                };
                Promise.all(newArray).then(value => {
                    for (let i = 0; i < value.length; i++) {
                        // var newArray = [],
                        var kechuku = 0;
                        if (value[i].data) {
                            var realValue = JSON.parse(value[i].data);
                            realData.data.salesOrderItem[i].bencichuku = 0;
                            realData.data.salesOrderItem[i].checkAll = false;
                            realData.data.salesOrderItem[i].selectedRowKeys = [];
                            for (let y = 0; y < realValue.data.length; y++) {
                                realValue.data[y].index = i;
                                kechuku = kechuku + Number(realValue.data[y].bigQty);
                                realValue.data[y].price = realData.data.salesOrderItem[i].backnowledgedPrice;
                                realValue.data[y].numberThis = realData.data.salesOrderItem[i].backnowledgedQty;
                                realValue.data[y].key = y;
                                realValue.data[y].selectedTableRow = false;

                            }
                            realData.data.salesOrderItem[i].searchLength = realData.data.length;
                            realData.data.salesOrderItem[i].kechuku = kechuku;
                            realData.data.salesOrderItem[i].tableList = realValue.data;
                            // 根据入库时间排序 最早入库的在前
                            realData.data.salesOrderItem[i].tableList.sort(function(a,b){
                                return a.inInvDate > b.inInvDate ? 1 : -1
                            })
                        }
                    }
                    console.log('订单详情!!!',realData.data)
                     // 获取专供
                    this.orderDetail.orderHeader = realData.data;
                    // 查询服务费
                    // let itemIds = this.orderDetail.orderHeader.salesOrderItem.map(it=>it.itemId)
                    // let data  = {
                    //     orderType:2,
                    //     orderItemIds: itemIds
                    // }
                    // selectServiceFree(data).then(async(resA)=>{
                    //     if(res.data.code == 0) {
                    //         this.serviceFeeTotal = resA.data.data.total
                    //         res.data.orderLines.forEach(item=>{
                    //             this.orderDetail.orderHeader.salesOrderItem.forEach(itemTwo=>{
                    //                 item.serviceFee = 0
                    //                 if(item.itemId == itemTwo.orderItemId) {
                    //                     // item.serviceFee = itemTwo.serviceFee
                    //                     this.$nextTick(()=> {
                    //                         this.$set(item,'serviceFee',itemTwo.serviceFee)
                    //                     })
                    //                 }
                    //             })
                    //         })
                    //     } else {
                    //         this.serviceFeeTotal = 0
                    //     }
                    //
                    // })
                    this.NeworderDetail.NeworderHeader = JSON.parse(JSON.stringify(realData.data));
                })
            })
        },
        // bucha
        bucha(orgCode) {
            let data = {
                orgCode,
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
            }
            difference(data).then(res => {
                var realData = JSON.parse(res.data);
                this.buchais = realData.data;
            })
        },
        // 搜索仓库
        tableSearchClick(index) {
            var newarray = [];
            for (let item of this.orderDetail.orderHeader.salesOrderItem[index].tableList) {
                var indexok = item.warehouseName.indexOf(this.tableSearch)
                if (indexok != -1) {
                    newarray.push(item);
                }
            }
            let tota = 0
            for (var i = 0; i < newarray.length; i++) {
                tota += Number(newarray[i].bigQty)
            }
            this.orderDetail.orderHeader.salesOrderItem[index].kechuku = tota;
            this.orderDetail.orderHeader.salesOrderItem[index].searchLength = newarray.length;
            this.orderDetail.orderHeader.salesOrderItem[index].tableList = newarray;
        },
        // 搜索框清空
        handleinput(index, e) {
            let tota = 0

            this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
            for (var i = 0; i < this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList.length; i++) {
                tota += Number(this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList[i].bigQty)
            }
            this.orderDetail.orderHeader.salesOrderItem[index].kechuku = tota;
        },
        // 清空选择
        clearChose(index) {
          if (this.type !='CK'){
              this.selectedRowKeys = [];
              this.orderDetail.orderHeader.salesOrderItem[index].checkAll = false;
              this.orderDetail.orderHeader.salesOrderItem[index].selectedRowKeys = [];
              this.sendObject[index] = [];
              this.orderDetail.orderHeader.salesOrderItem[index].tableList.forEach(item=>{
                  item.selectedTableRow = false
                  this.$forceUpdate()
              })
              this.orderDetail.orderHeader.salesOrderItem[index].bencichuku = 0
              this.chukuTotal = 0
              this.chukuVolume = 0
              this.paymentGoods = 0
              this.orderDetail.orderHeader.salesOrderItem.forEach(item=>{
                  this.chukuTotal += item.bencichuku
                  this.chukuVolume += (item.bencichuku)*(item.volume)
                  this.paymentGoods += (item.bencichuku)*(item.backnowledgedPrice)
              })
              this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
          } else {
              this.selectedRowKeys = [];
              this.orderDetail.orderHeader.salesOrderItem[index].checkAll = false;
              this.orderDetail.orderHeader.salesOrderItem[index].selectedRowKeys = [];
              this.sendObject[index] = [];
              var goodsNum = 0,
                goodsVol = 0,
                goodsServiceFee = 0,
                goodsPaymentGoods = 0;
              for (var i = 0; i < this.sendObject.length; i++) {
                  for (var y = 0; y < this.sendObject[i].length; y++) {
                      goodsNum = goodsNum + this.sendObject[i][y].numberThis;
                      goodsVol = goodsVol + this.sendObject[i][y].volume
                      goodsServiceFee = goodsServiceFee + this.sendObject[i][y].price
                      goodsPaymentGoods = goodsServiceFee + this.sendObject[i][y].price
                  }
              }
              this.chukuTotal = goodsNum;
              this.chukuVolume = goodsVol;
              // 服务费合计暂时这么计算 后期需要修改
              this.serviceFee = goodsServiceFee;
              this.paymentGoods = goodsPaymentGoods
          }
        },
        onSelect(record, selected, selectedRows) {
            record.selectedTableRow = selected;
            var totalIndex = record.index;
            this.sendObject[totalIndex] = selectedRows;
            if (!selected) {
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku - record.numberThis;
                record.numberThis = 0;
            }
            var newarray = [];
            for (let i = 0; i < selectedRows.length; i++) {
                newarray.push(selectedRows[i].key);
            }
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;

            var goodsnum = 0,
              goodsvol = 0,
              goodsServiceFee = 0,
              goodsPaymentGoods = 0;
            for (var y = 0; y < this.sendObject.length; y++) {
                if (this.sendObject[y]) {
                    for (var z = 0; z < this.sendObject[y].length; z++) {
                        goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                        // 修改体积计算错误问题
                        goodsvol = (Number(goodsvol) + (Number(this.sendObject[y][z].volume) * Number(this.sendObject[y][z].numberThis))).toFixed(3)
                        goodsServiceFee = (Number(goodsServiceFee) + (Number(this.sendObject[y][z].price) * Number(this.sendObject[y][z].numberThis))).toFixed(2)
                        goodsPaymentGoods = (Number(goodsPaymentGoods) + (Number(this.sendObject[y][z].price) * Number(this.sendObject[y][z].numberThis))).toFixed(2)

                    }
                }
            }
            this.chukuTotal = goodsnum;
            this.chukuVolume = goodsvol;
            this.serviceFee = goodsServiceFee;
            this.paymentGoods = goodsPaymentGoods;
            // 选中的数据是统仓的数量  如果是统仓并且已经开通了贷款的数量大于0的就展示提示文字
            let tableList = []
            if(this.orderDetail.orderHeader.salesOrderItem.length > 0) {
                for(let i = 0; i < this.orderDetail.orderHeader.salesOrderItem.length;i++) {
                    if(this.orderDetail.orderHeader.salesOrderItem[i].tableList.length > 0) {
                        tableList.push (...this.orderDetail.orderHeader.salesOrderItem[i].tableList)
                    }
                }
            }

            this.filterArr = tableList.filter(item=>(item.selectedTableRow && item.gicWarehouseType== 20))
        },
        gotoCK(){
            this.$router.push({
                path:"/stockCenter/myStock"
            });
        },

        // 表格全选
        onSelectAll(selected, selectedRows, changeRows) {
            var chosenumber = 0;
            var newarray = []
            var totalIndex = changeRows.length > 0 ? changeRows[0].index :0;

            this.sendObject[totalIndex] = selectedRows;
            if (selected) {
                for (let i = 0; i < changeRows.length; i++) {
                    newarray.push(changeRows[i].key)
                    changeRows[i].selectedTableRow = selected;
                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
            } else {
                for (let i = 0; i < changeRows.length; i++) {
                    chosenumber += Number(changeRows[i].numberThis)
                    changeRows[i].numberThis = 0;
                    changeRows[i].selectedTableRow = selected;
                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = 0
                this.chukuTotal = this.chukuTotal - chosenumber;
            }
            // 选中的数据是统仓的数量  如果是统仓并且已经开通了贷款的数量大于0的就展示提示文字
            let tableList = []
            if(this.orderDetail.orderHeader.salesOrderItem.length > 0) {
                for(let i = 0; i < this.orderDetail.orderHeader.salesOrderItem.length;i++) {
                    if(this.orderDetail.orderHeader.salesOrderItem[i].tableList.length > 0) {
                        tableList.push (...this.orderDetail.orderHeader.salesOrderItem[i].tableList)
                    }
                }
            }
            this.filterArr = tableList.filter(item=>(item.selectedTableRow && item.gicWarehouseType== 20))

        },

        // 表格审核全选
        onSelectAllSH(selected, selectedRows, changeRows) {
           let newarray = []
            let totalIndex = changeRows.length > 0 ? changeRows[0].index :0;
            if(selected){
                if(selectedRows.length>0){
                    selectedRows.forEach(item=>{
                        item.selectedTableRow = true
                        newarray.push(item.key)

                    })
                }
            }else{
                if(selectedRows.length>0){
                    selectedRows.forEach(item=>{
                        item.selectedTableRow = false
                        this.$forceUpdate()
                        newarray=[]
                    })
                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].tableList.forEach(item=>{
                    item.selectedTableRow = false
                    this.$forceUpdate()
                })
            }

            this.sendObject[totalIndex] = selectedRows

            let num = 0
            this.sendObject[totalIndex].forEach(item=>{
                num+=Number(item.numberThis)
            })
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num
            this.chukuTotal = 0
            this.chukuVolume = 0
            this.paymentGoods = 0
            this.orderDetail.orderHeader.salesOrderItem.forEach(item=>{
                this.chukuTotal += item.bencichuku
                this.chukuVolume += (item.bencichuku)*(item.volume)
                this.paymentGoods += (item.bencichuku)*(item.backnowledgedPrice)
            })
            this.brightNum = 0
            this.brightPrice = 0
            this.olnyNum = 0
            this.olnyPrice = 0
            // 获取专供
            const zg =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
            if(zg.length>0) {
                zg.forEach(el=>{
                    this.olnyNum+=Number(el.bencichuku) 
                    this.olnyPrice+=Number(el.bencichuku) * Number(el.backnowledgedPrice);
                })
            }
            
            // 获取璀璨
            const cc =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
            if(cc.length>0){
                cc.forEach(el=>{
                    this.brightNum+=Number(el.bencichuku) 
                    this.brightPrice+=Number(el.bencichuku) * Number(el.backnowledgedPrice);
                })
            }
            // 选中的数据是统仓的数量  如果是统仓并且已经开通了贷款的数量大于0的就展示提示文字
            let tableList = []
            if(this.orderDetail.orderHeader.salesOrderItem.length > 0) {
                for(let i = 0; i < this.orderDetail.orderHeader.salesOrderItem.length;i++) {
                    if(this.orderDetail.orderHeader.salesOrderItem[i].tableList.length > 0) {
                        tableList.push (...this.orderDetail.orderHeader.salesOrderItem[i].tableList)
                    }
                }
            }
            this.filterArr = tableList.filter(item=>(item.selectedTableRow && item.gicWarehouseType== 20))

        },
        //审核单端
        onSelectSH(record, selected, selectedRows) {
            // console.log('选择仓库',record,selected,selectedRows)
            // 是工程订单
            // if(this.orderDetail.orderHeader.engineeringCode){
            //     // 当前所选不是工程机
            //     if(record.priceGroupNumber !== '04'  && record.inOrgCode && record.inOrgCode.startsWith("68")){
            //         this.$message.warning("仅支持定价组为工程机的产品");
            //         return
            //     }
            // }
            // // 当前不是工程订单
            // if(!this.orderDetail.orderHeader.engineeringCode){
            //     // 当前所选是工程机
            //     if(record.priceGroupNumber === '04' && record.inOrgCode && record.inOrgCode.startsWith("68")){
            //         this.$message.warning("不支持定价组为工程机的产品");
            //         return
            //     }
            // }
            record.selectedTableRow = selected;
            var totalIndex = record.index;
            this.sendObject[totalIndex] = selectedRows;
            if (!selected) {
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku - record.numberThis;
                record.numberThis = this.orderDetail.orderHeader.salesOrderItem[totalIndex].backnowledgedQty;
            }
            var newarray = [];
            for (let i = 0; i < selectedRows.length; i++) {
                newarray.push(selectedRows[i].key);
            }
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;

            var goodsnum = 0,
                goodsvol = 0,
                goodsPaymentGoods=0;
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = 0
            this.sendObject[totalIndex].forEach(el=>{
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku+=Number(el.numberThis)
            })
            for (var y = 0; y < this.sendObject.length; y++) {
                if (this.sendObject[y]) {
                    for (var z = 0; z < this.sendObject[y].length; z++) {
                        goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                        // 修改体积计算错误问题
                        goodsvol = (Number(goodsvol) + (Number(this.sendObject[y][z].volume) * Number(this.sendObject[y][z].numberThis))).toFixed(3)
                        goodsPaymentGoods = (Number(goodsPaymentGoods) + (Number(this.sendObject[y][z].price) * Number(this.sendObject[y][z].numberThis))).toFixed(2)
                        // this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku += Number(this.sendObject[y][z].numberThis)
                    }
                }
            }


            this.chukuTotal = goodsnum;
            this.chukuVolume = goodsvol;
            this.paymentGoods = goodsPaymentGoods
            this.brightNum = 0
            this.brightPrice = 0
            this.olnyNum = 0
            this.olnyPrice = 0
            // 获取专供
            const zg =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
            if(zg.length>0) {
                zg.forEach(el=>{
                    this.olnyNum+=Number(el.bencichuku) 
                    this.olnyPrice+=Number(el.bencichuku) * Number(el.backnowledgedPrice);
                })
            }
            // 获取璀璨
            const cc =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
            if(cc.length>0) {
                cc.forEach(el=>{
                    this.brightNum+=Number(el.bencichuku) 
                    this.brightPrice+=Number(el.bencichuku) * Number(el.backnowledgedPrice);
                })
            }
            
            // 选中的数据是统仓的数量  如果是统仓并且已经开通了贷款的数量大于0的就展示提示文字
            let tableList = []
            if(this.orderDetail.orderHeader.salesOrderItem.length > 0) {
                for(let i = 0; i < this.orderDetail.orderHeader.salesOrderItem.length;i++) {
                    if(this.orderDetail.orderHeader.salesOrderItem[i].tableList.length > 0) {
                        tableList.push (...this.orderDetail.orderHeader.salesOrderItem[i].tableList)
                    }
                }
            }

            this.filterArr = tableList.filter(item=>(item.selectedTableRow && item.gicWarehouseType== 20))
        },
        //出库单选
        onSelectCK(record, selected, selectedRows) {
            record.selectedTableRow = selected;
            var totalIndex = record.index;
            this.sendObject[totalIndex] = selectedRows;
            // 循环遍历表格 如果表格勾选为true 循环叠加每个numberThis
            let num = 0
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].tableList.forEach(item=>{
                if(item.selectedTableRow ) {
                    num += Number(item.numberThis)
                }
            })
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num

            var newarray = [];
            for (let i = 0; i < selectedRows.length; i++) {
                newarray.push(selectedRows[i].key);
            }
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
            // 计算选中商品 体积 数量
            var goodsnum = 0,
              goodsvol = 0,
              goodsPaymentGoods=0;
            for (var y = 0; y < this.sendObject.length; y++) {
                if (this.sendObject[y]) {
                    for (var z = 0; z < this.sendObject[y].length; z++) {
                        goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                        // 修改体积计算错误
                        goodsvol = (Number(goodsvol) + (Number(this.sendObject[y][z].volume)* Number(this.sendObject[y][z].numberThis))).toFixed(3)
                        goodsPaymentGoods = (Number(goodsPaymentGoods) + (Number(this.sendObject[y][z].price) * Number(this.sendObject[y][z].numberThis))).toFixed(2)
                        if(this.sendObject[y][z]&&this.sendObject[y][z].gicWarehouseType == 20) {
                        }
                    }
                }
            }
            this.chukuTotal = goodsnum;
            this.chukuVolume = goodsvol;
            this.paymentGoods = goodsPaymentGoods;
             // 获取专供
             const zg =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
             if(zg.length>0){
                zg.forEach(el=>{
                    this.olnyNum+=Number(el.bencichuku) 
                    this.olnyPrice+=Number(el.bencichuku) * Number(el.price);
                })
            }
             // 获取璀璨
             const cc =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
             if(cc.length>0) {
                cc.forEach(el=>{
                    this.brightNum+=Number(el.bencichuku) 
                    this.brightPrice+=Number(el.bencichuku) * Number(el.price);
                })
             }
             
        },
        // 表格出库全选
        onSelectAllCK(selected, selectedRows, changeRows) {
            var newarray = [];
            var chosenumber = 0;
            var totalIndex = changeRows[0].index;
            this.sendObject[totalIndex] = selectedRows;
            if (selected) {
                for (let i = 0; i < changeRows.length; i++) {
                    newarray.push(changeRows[i].key)
                    changeRows[i].selectedTableRow = selected;
                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
                // 循环遍历表格 如果表格勾选为true 循环叠加每个numberThis
                let num = 0
                changeRows.forEach(item=>{
                    if(item.selectedTableRow ) {
                        num += Number(item.numberThis)
                    }
                })
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num
                var goodsnum = 0,
                  goodsvol = 0;
                for (var y = 0; y < this.sendObject.length; y++) {
                    if (this.sendObject[y]) {
                        for (var z = 0; z < this.sendObject[y].length; z++) {
                            goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                            goodsvol = goodsvol + Number(this.sendObject[y][z].volume)
                            if(this.sendObject[y][z]&&this.sendObject[y][z].gicWarehouseType == 20) {
                            }
                        }
                    }
                }
                this.chukuTotal = goodsnum;
                this.chukuVolume = goodsvol;
            } else {
                for (let i = 0; i < changeRows.length; i++) {
                    chosenumber += Number(changeRows[i].numberThis)
                    changeRows[i].numberThis = 0;
                    changeRows[i].selectedTableRow = selected;

                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;

                let num = 0
                changeRows.forEach(item=>{
                    if(item.selectedTableRow ) {
                        num += Number(item.numberThis)
                    }

                })
                this.brightNum = 0
                this.brightPrice = 0
                this.olnyNum = 0
                this.olnyPrice = 0
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num
                 // 获取专供
                const zg =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
                if(zg.length>0) {
                    zg.forEach(el=>{
                        this.olnyNum+=Number(el.bencichuku) 
                        this.olnyPrice+=Number(el.bencichuku) * Number(el.price);
                    })
                }
                
                // 获取璀璨
                const cc =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
                if(cc.length>0) {
                    cc.forEach(el=>{
                        this.brightNum+=Number(el.bencichuku) 
                        this.brightPrice+=Number(el.bencichuku) * Number(el.price);
                    })
                }
                this.chukuTotal = this.chukuTotal - chosenumber;
                this.openCloseBtn = false
                this.cherkStatus = false
            }

        },
        // 仅查看已选
        onlyShowCheckedChange(item, index, e) {
            if (e.target.checked) {
                this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.sendObject[index];
            } else {
                this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
            }
            this.orderDetail.orderHeader.salesOrderItem[index].checkAll = e.target.checked;
        },
        //出库数量改变
        inputNumberChange(row) {
            var realNumber = this.orderDetail.orderHeader.salesOrderItem[row.index];
            // var realNumber = this.sendObject[row.index];
            var realData = this.orderDetail.orderHeader.salesOrderItem;

            var chukuNumber = 0;
            for (let i = 0; i < realNumber.tableList.length; i++) {
                chukuNumber += Number(realNumber.tableList[i].numberThis);
            }
            // 本次出库数量重置
            this.orderDetail.orderHeader.salesOrderItem[row.index].bencichuku = 0
            this.sendObject[row.index].forEach(item=>{
                this.orderDetail.orderHeader.salesOrderItem[row.index].bencichuku += Number(item.numberThis)
            })

            var chukuzong = 0,
                chukutiji = 0,
                chukuFWF = 0,
                chukuhk= 0
            for (let y = 0; y < realData.length; y++) {
                for (let z = 0; z < realData[y].tableList.length; z++) {
                    if(realData[y].tableList[z].selectedTableRow){
                        chukuzong += Number(realData[y].tableList[z].numberThis);
                        chukutiji += Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].volume);
                        chukuFWF += Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].price);
                        chukuhk += Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].price);
                    }

                }
            }
            this.chukuTotal = chukuzong;
            this.chukuVolume = this.$util.getFloat(chukutiji, 3);
            this.serviceFee = this.$util.getFloat(chukuFWF, 2);
            this.paymentGoods =  this.$util.getFloat(chukuhk, 2);
            this.brightNum = 0
            this.brightPrice = 0
            this.olnyNum = 0
            this.olnyPrice = 0
            // 获取专供
            const zg =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
            if(zg.length>0) {
                zg.forEach(el=>{
                    this.olnyNum+=Number(el.bencichuku) 
                    this.olnyPrice+=Number(el.bencichuku) * Number(el.backnowledgedPrice);
                })
            }
            // 获取璀璨
            const cc =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
            if(cc.length>0) {
                cc.forEach(el=>{
                    this.brightNum+=Number(el.bencichuku) 
                    this.brightPrice+=Number(el.bencichuku) * Number(el.backnowledgedPrice);
                })
            }
        },
        //出库数量改变
        inputNumberChangeCK(row) {
            var realNumber = this.orderDetail.orderHeader.salesOrderItem[row.index];
            var realData = this.orderDetail.orderHeader.salesOrderItem;
            var chukuNumber = 0;
            for (let i = 0; i < realNumber.tableList.length; i++) {
                if(realNumber.tableList[i].selectedTableRow) {
                    chukuNumber += Number(realNumber.tableList[i].numberThis);
                }
            }
            this.orderDetail.orderHeader.salesOrderItem[row.index].bencichuku = chukuNumber;
            var chukuzong = 0
            var chukutiji = 0
            var chukuhk= 0
            for (let y = 0; y < realData.length; y++) {
                chukuzong += Number(realData[y].bencichuku);
                chukutiji += Number(Number(realData[y].bencichuku) * realData[y].volume);
                for (let z = 0; z < realData[y].tableList.length; z++) {
                    if(realData[y].tableList[z].selectedTableRow){
                        chukuhk += Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].price);
                    }
                 }
            }
            this.chukuTotal = chukuzong;
            this.chukuVolume = this.$util.getFloat(chukutiji, 3);
            this.paymentGoods =  this.$util.getFloat(chukuhk, 2);
             // 获取专供
             const zg =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
             if(zg.length>0) {
                zg.forEach(el=>{
                    this.olnyNum+=Number(el.bencichuku) 
                    this.olnyPrice+=Number(el.bencichuku) * Number(el.price);
                })
            }
             // 获取璀璨
             const cc =  this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
             if(cc.length>0) {
                cc.forEach(el=>{
                    this.brightNum+=Number(el.bencichuku) 
                    this.brightPrice+=Number(el.bencichuku) * Number(el.price);
                })
                }
        },
        //审核通过提交
        orderSubmit() {
            this.notFullyReviewed = []
            if(this.openCloseBtn && !this.cherkStatus) {
                this.$message.warning("请确认质物明细！");
                return;
            }
            var realData = this.sendObject;
            if (realData.length == 0) {
                this.$message.warning('请先选择仓库');
                return;
            } else {
                var realValue = this.orderDetail.orderHeader.salesOrderItem;
                this.submitArraySH = [];
                for (let i = 0; i < realData.length; i++) {
                    var submitData = {},
                        outThis = 0;
                    if (realData[i]) {
                        for (let z = 0; z < realData[i].length; z++) {
                            outThis = outThis + Number(realData[i][z].numberThis);
                            console.log('realData[i][z].outThis',realValue[i])
                            if (realValue[i].bencichuku== 0) {
                                this.$message.warning('第' + Number(i + 1) + '个商品出库数量不能为0')
                                return;
                            }
                            if (outThis > realValue[i].backnowledgedQty) {
                                this.$message.warning('第' + Number(i + 1) + '个商品出库数量大于商品该出库数量')
                                return;
                            }
                            console.log(realValue[i].bencichuku,realValue[i].backnowledgedQty)
                            if (realValue[i].bencichuku < realValue[i].backnowledgedQty) {
                                this.notFullyReviewed.push(realValue[i])
                            }

                            if(realData[i][z].numberThis > 0){
                                submitData = {
                                    materialCode:realData[i][z].materialCode,
                                    productCode: realData[i][z].productCode,
                                    invStatus: realData[i][z].invStatusId,
                                    invStatusType: realData[i][z].invStatusType,
                                    gicOutWarehouse: realData[i][z].gicWarehouse == '' ? realData[i][z].warehouseId : realData[i][z].gicWarehouse,
                                    backnowledgedQty: realData[i][z].numberThis
                                }
                                this.submitArraySH.push(submitData);
                            }
                        }
                    }
                }
                if (this.submitArraySH.length == 0) {
                    this.$message.warning('请先选择仓库');
                    return;
                }
                console.log('this.daichuku',this.daichuku)
                console.log(this.chukuTotal)
                if (this.daichuku < this.chukuTotal) {
                    this.$message.warning('出库数量不得大于待出库数量');
                    return;
                }
                if(this.notFullyReviewed.length>0){
                    let message = []
                    this.notFullyReviewed.forEach(item=>{
                        message.push(item.model)
                    })
                    this.tipMessage='当前订单'+message.join('，')+'型号审核出库数量与采购单数量不一致，请核对'
                    this.tipMessageVisible=true
                    return
                }
               this.sumbitApi()
            }
        },
        handleSubmit(){
            this.sumbitApi()
        },
        sumbitApi(){
            this.isLoading = true;
            // 订单出库
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                userAccount: this.$store.state.user.userInfo.account.account,
                orderItem: this.submitArraySH,
                salesOrderId: this.$route.query.id,
                dataSource: 'xtw_pc'
            }
            orderPass(data).then(res => {
                this.isLoading = false;
                var realres = JSON.parse(res.data);
                if (realres.code == '1') {
                    // this.$message.warning(realres.msg);
                    this.showInfo = realres.msg
                    this.visibleAAA = true
                } else if (realres.code == '0') {
                    this.$message.success('审核成功')
                    localStorage.setItem('XTW', new Date());
                    localStorage.setItem("saleOrderListTime", new Date().getTime());
                    window.close()
                    this.$router.push('/channelOrder/orderList');
                }
            }).finally(() => {
                this.isLoading = false;
            })
        },
        // 审核驳回
        orderReturn() {
            this.isLoadingReturn = true;
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                salesOrderId: this.$route.query.id,
                userAccount: this.$store.state.user.userInfo.account.account,
            }
            orderReject(data).then(res => {
                this.isLoadingReturn = false;
                var realData = JSON.parse(res.data);
                if (realData.code == '0') {
                    this.$message.success('驳回成功');

                    if (this.orderDetail.orderHeader.userActivityCode) {
                        let userActivityCode = this.orderDetail.orderHeader.userActivityCode
                        let productIds = []
                        let dmsOrderCode = this.orderDetail.orderHeader.purchaseNum
                        let qtys = []

                        this.orderDetail.orderHeader.salesOrderItem.forEach(element => {
                            productIds.push(element.productCode)
                            qtys.push(-(element.backnowledgedQty))
                        });
                        let changeDta = {
                            userActivityCode: userActivityCode,
                            productIds: productIds.join(','),
                            dmsOrderCode: dmsOrderCode,
                            qtys: qtys.join(',')
                        }
                        changeTransFlag(changeDta).then(res => {
                        })
                    }
                    localStorage.setItem('XTW', new Date());
                    window.close()
                    this.$router.push('/channelOrder/orderList');
                } else {
                    this.$message.warning(res.data.msg);
                }
            })
        },
        // 取消
        orderCancle() {
            localStorage.setItem('XTW', new Date());
            window.close()
            this.$router.push('/channelOrder/orderList');
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
    }
};